// Code to render the track details page, including the track information, audio player, and feature visualizers
// Imports for the TrackDetails component
import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import TempoGauge from "./TempoGauge";
import LoudnessGauge from "./LoudnessGauge";
import MusicPanel from "./MusicPanel";
import SpotifyPlayer from "./SpotifyPlayer";
import { Tooltip } from "react-tooltip";
import SliderGroup from "./FeatureSlider";
import ControlPanel from "./ControlPanel";
import { AlbumCover } from "./utils";
import TooltipList from "./TooltipList";
import { generateTooltipDetails } from "./TooltipDetail";
import TrackPanel from "./TrackPanel";

// Function to generate mock features if no valid features are passed
const getMockFeatures = () => ({
  tempo: Math.random() * (200 - 60) + 60, // Random value between 60 and 200
  loudness: Math.random() * (5 - -60) + -60, // Random value between -60 and 5
  energy: Math.random(), // Random value between 0 and 1
  danceability: Math.random(), // Random value between 0 and 1
  instrumentalness: Math.random(), // Random value between 0 and 1
  speechiness: Math.random(), // Random value between 0 and 1
  valence: Math.random(), // Random value between 0 and 1
  acousticness: Math.random(), // Random value between 0 and 1
  liveness: Math.random(), // Random value between 0 and 1
  key: Math.floor(Math.random() * 12), // Random integer between 0 and 11
  mode: Math.random() > 0.5 ? 1 : 0, // 1 for major, 0 for minor
  time_signature: Math.floor(Math.random() * 4) + 3, // Random integer between 3 and 6
  popularity: Math.floor(Math.random() * 101), // Random integer between 0 and 100
});

// TrackDetails component
const TrackDetails = ({ track, features, selectedFeature }) => {
  const navigate = useNavigate();

  // State for track features (using mock data if no valid features are passed)
  const [trackFeatures, setTrackFeatures] = useState(getMockFeatures());

  // State to toggle between text and visualizer views
  const [isTextView, setIsTextView] = useState(true);

  // State to force a scroll to the top of the page whenever the track changes
  useEffect(() => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  }, [track]);

  // If there is no track to display (e.g., if the user navigates directly to the page), redirect to the homepage
  useEffect(() => {
    if (!track) {
      navigate("/");
    }
  }, [track, navigate]);

  // Update the track features whenever the track or features change
  useEffect(() => {
    // Get the selected feature if it exists, otherwise mock the features.
    const newFeatures = selectedFeature
      ? { ...getMockFeatures(), ...selectedFeature } // Apply the selected feature if it exists
      : features &&
        typeof features === "object" &&
        !Object.values(features).includes(NaN)
      ? features
      : getMockFeatures();

    // Sanitise and set the track features
    setTrackFeatures({
      tempo: !isNaN(newFeatures.tempo)
        ? newFeatures.tempo
        : getMockFeatures().tempo,
      loudness: !isNaN(newFeatures.loudness)
        ? newFeatures.loudness
        : getMockFeatures().loudness,
      energy: !isNaN(newFeatures.energy)
        ? newFeatures.energy
        : getMockFeatures().energy,
      danceability: !isNaN(newFeatures.danceability)
        ? newFeatures.danceability
        : getMockFeatures().danceability,
      instrumentalness: !isNaN(newFeatures.instrumentalness)
        ? newFeatures.instrumentalness
        : getMockFeatures().instrumentalness,
      speechiness: !isNaN(newFeatures.speechiness)
        ? newFeatures.speechiness
        : getMockFeatures().speechiness,
      valence: !isNaN(newFeatures.valence)
        ? newFeatures.valence
        : getMockFeatures().valence,
      acousticness: !isNaN(newFeatures.acousticness)
        ? newFeatures.acousticness
        : getMockFeatures().acousticness,
      liveness: !isNaN(newFeatures.liveness)
        ? newFeatures.liveness
        : getMockFeatures().liveness,
      key: !isNaN(newFeatures.key) ? newFeatures.key : getMockFeatures().key,
      mode:
        newFeatures.mode === undefined
          ? getMockFeatures().mode
          : newFeatures.mode, // Default to random mode (0 or 1)
      time_signature: !isNaN(newFeatures.time_signature)
        ? newFeatures.time_signature
        : getMockFeatures().time_signature,
      popularity: !isNaN(newFeatures.popularity)
        ? newFeatures.popularity
        : getMockFeatures().popularity,
    });
  }, [track, features, selectedFeature]);

  // Toggle view function
  const toggleView = () => {
    setIsTextView(!isTextView);
  };

  // Prepare tooltip details
  const tooltipDetails = generateTooltipDetails
    ? generateTooltipDetails(track, trackFeatures)
    : [];

  // Get the tempo and loudness tooltip details
  const loudnessTooltip = tooltipDetails.find(
    (detail) => detail.id === "loudness"
  );
  const tempoTooltip = tooltipDetails.find((detail) => detail.id === "tempo");

  // Render the TrackDetails component
  return (
    <div className="mt-4 p-6 bg-black">
      {/* Toggle Switch for View */}
      <div className="flex justify-between ">
        {/* Back Button */}
        <button
          onClick={() => navigate(-1)}
          data-tooltip-id="back-toggle"
          data-tooltip-content="Return to Previous Page"
          className="bg-gray-800 hover:bg-gray-300 p-2 rounded transition duration-200"
          aria-label="Back Button"
          role="button"
        >
          ← Back
        </button>
        <Tooltip id="back-toggle" className="max-w-[400px] break-words" />

        {/* View Toggle Button */}
        <button
          onClick={toggleView}
          data-tooltip-id="view-toggle"
          data-tooltip-content={
            isTextView
              ? "Displays Track Information in a sound mixing desk aesthetic"
              : "Displays Track Information in list format"
          }
          className={`p-2 rounded transition duration-200 ${
            isTextView ? "bg-green-500 text-black" : "bg-gray-800 text-white"
          }`}
          aria-label="Visualiser to Details Button"
          role="button"
        >
          {isTextView ? "Show Visualiser" : "Show Details"}
        </button>

        <Tooltip id="view-toggle" className="max-w-[400px] break-words" />
      </div>

      {isTextView ? (
        <div className="Text flex flex-col items-center justify-center">
          {/*DETAILS TEXT VIEW FOR ACCESSIBILITY*/}
          {/*Album Cover */}
          <AlbumCover
            src={track?.album?.images[0]?.url}
            alt={`${track?.album?.name} album cover`}
            size={250}
          />

          {/* Track Name */}
          <h2
            className="text-3xl font-bold text-white mt-4"
            data-tooltip-id="track"
            data-tooltip-content="The name of the track."
          >
            {track?.name}
          </h2>
          <Tooltip id="track" className="max-w-[400px] break-words" />

          {/* Track Details Array */}
          <div className="mt-4 flex-wrap max-w-[300px] text-center">
            <TooltipList features={trackFeatures} track={track} />
          </div>
        </div>
      ) : (
        <div className="Visualiser">
          {/*VISUALISER VIEW FOR 3 SCREEN SIZES*/}
          {/* Desktop Layout */}
          <div className="flex hidden xl:block justify-center max-w-screen-xl mx-auto">
            {/* Main Display */}
            <div className="flex justify-center items-center space-x-4">
              {/* Speed Column */}
              <div className="flex flex-col items-center space-y-4">
                <AlbumCover
                  src={track.album.images[0]?.url}
                  alt={`${track.album.name} album cover`}
                  size={250}
                />
                <TempoGauge
                  tempo={trackFeatures.tempo.toFixed(2) || 0}
                  tooltipId={tempoTooltip?.id}
                  tooltipContent={tempoTooltip?.content}
                />
              </div>

              {/* Panel Column */}
              <div className="flex flex-col items-center space-y-4">
                <TrackPanel features={trackFeatures} track={track} />
                <ControlPanel features={trackFeatures} track={track} />
              </div>

              {/* Noise Column */}
              <div className="flex flex-col items-center space-y-4">
                <SpotifyPlayer trackId={track.id} />
                <LoudnessGauge
                  loudness={trackFeatures.loudness.toFixed(2) || 0}
                  tooltipId={loudnessTooltip?.id}
                  tooltipContent={loudnessTooltip?.content}
                />
              </div>
            </div>

            {/* Feature Sliders */}
            <div className="flex justify-between items-center ">
              <SliderGroup
                features={trackFeatures}
                sliderType="vertical"
                labels={[
                  "energy",
                  "danceability",
                  "instrumentalness",
                  "speechiness",
                ]}
                tooltipDetails={tooltipDetails}
              />
              {/* Music Panel */}
              <MusicPanel features={trackFeatures} track={track} />
            </div>
          </div>

          {/* Mobile Layout */}
          <div className="block md:hidden">
            {/* Main Display */}
            <div className="flex flex-col justify-center items-center space-x-4">
              <AlbumCover
                src={track.album.images[0]?.url}
                alt={`${track.album.name} album cover`}
                size={250}
              />
              <TrackPanel features={trackFeatures} track={track} />
              <SpotifyPlayer trackId={track.id} />
            </div>

            {/* Tempo and Loudness */}
            <div className="flex justify-center">
              <TempoGauge
                tempo={trackFeatures.tempo.toFixed(2) || 0}
                tooltipId={tempoTooltip?.id}
                tooltipContent={tempoTooltip?.content}
              />
            </div>
            <div className="flex justify-center">
              <LoudnessGauge
                loudness={trackFeatures.loudness.toFixed(2) || 0}
                tooltipId={loudnessTooltip?.id}
                tooltipContent={loudnessTooltip?.content}
              />
            </div>

            {/* Control Panel */}
            <ControlPanel features={trackFeatures} track={track} />
            <br></br>
            <SliderGroup
              features={trackFeatures}
              sliderType="horizontal"
              labels={[
                "energy",
                "danceability",
                "instrumentalness",
                "speechiness",
              ]}
              tooltipDetails={tooltipDetails}
            />
            {/* Music Panel */}
            <MusicPanel features={trackFeatures} track={track} />
          </div>

          {/* Mid Display */}
          <div className="custom-md-lg hidden md:block xl:hidden">
            {/* Main Display */}
            <div className="flex justify-between items-center space-x-4">
              <AlbumCover
                src={track.album.images[0]?.url}
                alt={`${track.album.name} album cover`}
                size={250}
              />

              <TrackPanel features={trackFeatures} track={track} />

              <div>
                <SpotifyPlayer trackId={track.id} />
              </div>
            </div>

            {/* Tempo and Loudness */}
            <div className="flex justify-center mb-4">
              <TempoGauge
                tempo={trackFeatures.tempo.toFixed(2) || 0}
                tooltipId={tempoTooltip?.id}
                tooltipContent={tempoTooltip?.content}
              />

              <LoudnessGauge
                loudness={trackFeatures.loudness.toFixed(2) || 0}
                tooltipId={loudnessTooltip?.id}
                tooltipContent={loudnessTooltip?.content}
              />
            </div>

            {/* Control Panel */}
            <ControlPanel features={trackFeatures} track={track} />
            <br></br>

            {/* Feature Sliders */}
            <SliderGroup
              features={trackFeatures}
              sliderType="vertical"
              labels={[
                "energy",
                "danceability",
                "instrumentalness",
                "speechiness",
              ]}
              tooltipDetails={tooltipDetails}
            />
            <br></br>
            {/* Music Panel */}
            <MusicPanel features={trackFeatures} track={track} />
          </div>
        </div>
      )}
    </div>
  );
};

// Export the TrackDetails component
export default TrackDetails;
