// Code to render the explicit switch component which shows if a song is explicit or not
// Imports for the component
import React from "react";
import SwitchSelector from "react-switch-selector";
import styled from "styled-components";
import { TooltipTag } from "./TooltipDetail";

// Styling for the explicit switch component
const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  width: 120px;
  height: 50px;
  pointer-events: none;
`;

// ExplicitSwitch component to render the explicit switch
const ExplicitSwitch = ({ isExplicit, tooltipId, tooltipContent }) => {
  const options = [
    {
      label: <span>Clean</span>,
      value: false, // Clean (not explicit)
      selectedBackgroundColor: "#1ED760", // Green for clean
      id: "explicit-clean",
      name: "explicit-switch",
    },
    {
      label: <span>Explicit</span>,
      value: true, // Explicit
      selectedBackgroundColor: "#FF0000", // Red for explicit
      id: "explicit-explicit",
      name: "explicit-switch",
    },
  ];

  // Find the index of the selected option
  const initialSelectedIndex = options.findIndex(
    (option) => option.value === isExplicit
  );

  // Return the explicit switch component
  return (
    // TooltipTag to show the tooltip when hovering over the explicit switch
    <TooltipTag tooltipId={tooltipId} tooltipContent={tooltipContent}>
      <StyledContainer>
        <SwitchSelector
          options={options}
          initialSelectedIndex={initialSelectedIndex}
          backgroundColor="#353B48" // Background color when not selected
          fontColor="#F5F6FA" // Text color
          selectedFontColor="#FFFFFF" // Text color when selected
          style={{ width: 120, height: 50 }}
          onChange={() => {}} // Keep non-interactive
        />
      </StyledContainer>
    </TooltipTag>
  );
};

// Export the ExplicitSwitch component
export default ExplicitSwitch;
