// Code to render the Popularity Slider component which shows the value of the popularity feature
// Popularity is a measure of how popular a song is, with higher values indicating more popular songs.
// Imports for the component
import React from "react";
import styled from "styled-components";
import ReactSlider from "react-slider";
import { TooltipTag } from "./TooltipDetail";

// Styling for the popularity slider component
const StyledSlider = styled(ReactSlider)`
  width: 100%;
  height: 25px;
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #d30000 0%, #ffde21 50%, #1ed760 100%);
    border-radius: 999px;
    z-index: 1; /* Ensure it is behind the thumb */
  }
`;

// Styling for the thumb of the popularity slider component
const StyledThumb = styled.div`
  height: 25px;
  width: 25px;
  border-radius: 50%;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  z-index: 2; /* Ensure thumb is above the track */
`;

// Thumb component to render the thumb of the popularity slider
const Thumb = ({ key: keyProp, ...props }, state) => {
  const uniqueKey = `thumb-${state.valueNow}`; // Using valueNow to create a unique key
  let emoji;

  // Assigning emojis based on the value range
  if (state.valueNow === 100) {
    emoji = "💯"; // "100" emoji for value 100
  } else if (state.valueNow >= 50) {
    emoji = "👍"; // Thumbs up for 50-99
  } else {
    emoji = "👎"; // Thumbs down for 0-49
  }

  // Rendering the thumb with the corresponding emoji
  return (
    <StyledThumb key={uniqueKey} {...props}>
      {emoji}
    </StyledThumb>
  );
};

// Styled container for the popularity slider component
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: full; /* Adjust the width as needed */
  margin: 20px;
`;

// PopSlider component to render the popularity slider
const PopSlider = ({ value, label, onChange, tooltipId, tooltipContent }) => (
  // Using the TooltipTag component to display the tooltip
  <TooltipTag tooltipId={tooltipId} tooltipContent={tooltipContent}>
    <StyledContainer>
      <div className="flex gap-x-5 items-center min-w-full">
        <StyledSlider
          value={value}
          min={0}
          max={100}
          orientation="horizontal"
          renderThumb={Thumb}
          onAfterChange={onChange}
          disabled={true}
        />
        <div className="lcd-box p-2 rounded-lg bg-green-300 text-black text-center w-[80px]">
          <p>{value}</p>
        </div>
      </div>
      <p className="text-white font-bold mt-2">{label}</p>
    </StyledContainer>
  </TooltipTag>
);

// Exporting the PopSlider component
export default PopSlider;
