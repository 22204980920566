// Code to render the sample songs component which displays a list of sample songs
// Imports for the SampleSongs component
import React, { useEffect, useState } from "react";
import { Tooltip } from "react-tooltip";

// SampleSongs component
const SampleSongs = ({ onSelect }) => {
  const [sampleSongs, setSampleSongs] = useState([]);

  // Fetch sample songs from the API
  useEffect(() => {
    const fetchSampleSongs = async () => {
      try {
        // Ease of use commenting out for development/production
        //const response = await fetch(`http://localhost:5000/api/sample-songs`);
        const response = await fetch('/api/sample-songs');
        const data = await response.json();
        setSampleSongs(data);
      } catch (error) {
        console.error("Error fetching sample songs:", error);
      }
    };
    fetchSampleSongs();
  }, []);

  // Return the sample songs component
  return (
    <div className="mt-4">
      <h2 className="text-xl font-spotifyreg text-center">
        Here are some popular tracks to try out!
      </h2>
      <p className="text-center">
        Select a track to see interesting details about that song, it's even
        better in visualizer mode!
      </p>
      <div className="mt-4 flex flex-col items-center gap-4 md:flex-row md:flex-wrap md:justify-center">
        {sampleSongs.length > 0 ? (
          sampleSongs.map((track) => (
            <div
              // Display the sample songs as a grid of cards
              key={track.id}
              className="p-4 border rounded-lg shadow-lg cursor-pointer w-full max-w-[300px] md:w-1/6  md:min-h-[400px]" // Adjust width for responsiveness
              onClick={() => onSelect(track)}
              role="button"
              data-tooltip-id={`tooltip-${track.id}`}
              data-tooltip-content={`Click to view track details for ${track.name}`}
            >
              <img
                src={track.album.images[0]?.url}
                alt={`${track.name} album cover`}
                className="mt-2 w-full h-auto rounded-lg"
              />
              <h2 className="text-xl font-semibold">{track.name}</h2>
              <p className="text-gray-400">
                {track.artists.map((artist) => artist.name).join(", ")}
              </p>
              <Tooltip id={`tooltip-${track.id}`} />
            </div>
          ))
        ) : (
          // Display a message if no sample songs are available
          <p>No sample songs available.</p>
        )}
      </div>
    </div>
  );
};

// Export the SampleSongs component
export default SampleSongs;
