// Code to render the loudness gauge component which shows the loudness of a song
// Imports for the component
import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { TooltipTag } from "./TooltipDetail";

// LoudnessGauge component to render the loudness gauge
const LoudnessGauge = ({ loudness, tooltipId, tooltipContent }) => {
  const chartRef = useRef(null);

  // Use effect to render the loudness gauge
  useEffect(() => {
    if (chartRef.current) {
      const myChart = echarts.init(chartRef.current);

      const option = {
        series: [
          {
            // Set the options for the loudness gauge
            type: "gauge",
            min: -60,
            max: 0,
            radius: "50%",
            axisLine: {
              lineStyle: {
                color: [[1, "#FFFFFF"]],
                width: 3,
              },
            },
            splitLine: {
              // Set the style for the split line of the loudness gauge
              distance: -18,
              length: 18,
              lineStyle: {
                color: "#FFFFFF",
              },
            },
            axisTick: {
              // Set the style for the axis tick of the loudness gauge
              distance: -12,
              length: 10,
              lineStyle: {
                color: "#FFFFFF",
              },
            },
            axisLabel: {
              // Set the style for the axis label of the loudness gauge
              distance: -40,
              color: "#FFFFFF",
              fontSize: 15,
            },
            pointer: {
              // Set the style for the pointer of the loudness gauge
              show: true,
              length: "115%",
              itemStyle: {
                color: "#1ED760",
              },
            },
            detail: {
              // Set the formatter for the loudness gauge
              formatter: `{value} dB`,
              valueAnimation: false,
              fontSize: 15,
              color: "#FFFFFF",
              offsetCenter: {
                0: "0%",
                1: "100%",
              },
            },
            title: {
              // Set the title of the loudness gauge
              formatter: `Loudness (dB)`,
              fontSize: 15,
              color: "#FFFFFF",
              offsetCenter: {
                0: "0%",
                1: "130%",
              },
            },
            data: [
              {
                // Set the value of the loudness gauge
                value: loudness || 0,
                name: "Loudness (dB)",
              },
            ],
          },
        ],
      };

      myChart.setOption(option);

      // Handle resizing
      const handleResize = () => {
        myChart.resize();
      };

      window.addEventListener("resize", handleResize);

      // Clean up chart instance on component unmount
      return () => {
        myChart.dispose();
        window.removeEventListener("resize", handleResize);
      };
    }
  }, [loudness]);

  return (
    // TooltipTag to show the tooltip when hovering over the loudness gauge
    <TooltipTag tooltipId={tooltipId} tooltipContent={tooltipContent}>
      <div ref={chartRef} style={{ width: "300px", height: "300px" }} />
    </TooltipTag>
  );
};

// Export the LoudnessGauge component
export default LoudnessGauge;
