// Code to render the footer component which informs data is provided by Spotify and navigates to Spotify
// Imports for the component
import React from "react";
import { Tooltip } from "react-tooltip";

// Function to handle the Spotify logo click
const handleSpotifyClick = () => {
  window.open("https://open.spotify.com/", "_blank"); // Navigates to Spotify
};

// Footer component to render the footer
const Footer = () => {
  return (
    <footer className="bg-spotify-green text-black py-4 mt-4 text-center">
      <div className="flex justify-center items-center space-x-2">
        <p className="text-sm">
          © {new Date().getFullYear()} BEATDEETZ. Song data and Audio Playback
          provided by
        </p>
        <img
          src={`${process.env.PUBLIC_URL}/img/Spotify_Full_Black.png`}
          alt="Spotify Logo"
          onClick={handleSpotifyClick}
          className="h-8 w-auto cursor-pointer"
          role="button"
          aria-label="Link to Spotify"
          data-tooltip-id="SpotifyFooter"
          data-tooltip-content="Go to Spotify"
        />
        <Tooltip id={"SpotifyFooter"} />
      </div>
    </footer>
  );
};

// Export the Footer component
export default Footer;
