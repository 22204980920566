// Code to render the Valence Slider component which shows the value of the valence feature
// Valence is a measure of the positivity or negativity of a song, with higher values indicating more positive songs.
// Imports for the component
import React from "react";
import styled from "styled-components";
import ReactSlider from "react-slider";
import { TooltipTag } from "./TooltipDetail";

// Styling for the valence slider component
const StyledSlider = styled(ReactSlider)`
  width: 100%; /* Full width for horizontal slider */
  height: 25px;

  /* Using a pseudo-element to create a full-width background */
  &::before {
    content: "";
    position: absolute;
    height: 100%;
    width: 100%;
    background: linear-gradient(90deg, #1c0380 0%, #0080fe 50%, #1ed760 100%);
    border-radius: 999px;
    z-index: 1; /* Ensure it is behind the thumb */
  }
`;

// Styling for the thumb of the valence slider component
const StyledThumb = styled.div`
  height: 25px;
  width: 25px;
  background-color: #000;
  color: #ffffff;
  border-radius: 50%;
  cursor: default;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 40px;
  z-index: 2; /* Ensure thumb is above the track */
`;

// Thumb component to render the thumb of the valence slider
const Thumb = ({ key: keyProp, ...props }, state) => {
  const uniqueKey = `thumb-${state.valueNow}`; // Using valueNow to create a unique key
  let emoji;

  // Assigning emojis based on the value range
  if (state.valueNow >= 0 && state.valueNow <= 20) {
    emoji = "😭"; // Crying face for 0-20
  } else if (state.valueNow >= 21 && state.valueNow <= 40) {
    emoji = "😢"; // Sad face for 21-40
  } else if (state.valueNow >= 41 && state.valueNow <= 60) {
    emoji = "😐"; // Neutral face for 41-60
  } else if (state.valueNow >= 61 && state.valueNow <= 80) {
    emoji = "😊"; // Smiling face for 61-80
  } else {
    emoji = "😁"; // Grinning face for 81-100
  }

  // Rendering the thumb with the corresponding emoji
  return (
    <StyledThumb key={uniqueKey} {...props}>
      {emoji}
    </StyledThumb>
  );
};

// Container styling for the valence slider component
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: full; /* Adjust the width as needed */
  margin: 20px;
`;

// ValenceSlider component to render the valence slider
const ValenceSlider = ({
  value,
  label,
  onChange,
  tooltipId,
  tooltipContent,
}) => (
  // Using the TooltipTag component to display the tooltip
  <TooltipTag tooltipId={tooltipId} tooltipContent={tooltipContent}>
    <StyledContainer>
      <div className="flex gap-x-5 items-center min-w-full">
        <StyledSlider
          value={value}
          min={0}
          max={100}
          orientation="horizontal"
          renderThumb={Thumb}
          onAfterChange={onChange}
          disabled={true}
        />
        <div className="lcd-box p-2 rounded-lg bg-green-300 text-black text-center w-[80px]">
          <p>{value}</p>
        </div>
      </div>
      <p className="text-white font-bold mt-2">{label}</p>
    </StyledContainer>
  </TooltipTag>
);

// Exporting the ValenceSlider component
export default ValenceSlider;
