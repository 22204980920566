// Description: Utility functions for formatting and displaying data in the application.
// Imports the Tooltip component from the react-tooltip library.
import React from "react";
import { Tooltip } from "react-tooltip";

// Function to map the key to standard pitch class notation
export const mapKeyToNotation = (key) => {
  const keyMap = [
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#",
    "A",
    "A#",
    "B",
  ];
  return key === -1 ? "No key detected" : keyMap[key];
};

// Function to display time signature as a fraction
export const formatTimeSignature = (timeSignature) => {
  return `${timeSignature}/4`;
};

// Function to convert mode
export const mapMode = (mode) => {
  return mode === 0 ? "Minor" : mode === 1 ? "Major" : "Minor";
};

// Function to convert duration from milliseconds to minutes and seconds
export const formatDuration = (durationMs) => {
  const minutes = Math.floor(durationMs / 60000);
  const seconds = Math.floor((durationMs % 60000) / 1000);
  return `${minutes}:${seconds.toString().padStart(2, "0")}`;
};

// Function to display the album cover for a track
export const AlbumCover = ({ src, alt, size }) => (
  <div
    data-tooltip-id="album-art"
    data-tooltip-content="The cover art for the album."
  >
    <img
      src={src}
      alt={alt}
      className="mt-2 rounded-lg"
      style={{ height: `${size}px`, width: `${size}px` }}
    />
    <Tooltip id="album-art" className="max-w-[400px] break-words" />
  </div>
);

// Utility function to format feature values for display
export function formatFeatureValue(feature, value) {
  const keyMap = [
    "C",
    "C#",
    "D",
    "D#",
    "E",
    "F",
    "F#",
    "G",
    "G#",
    "A",
    "A#",
    "B",
  ];

  switch (feature) {
    case "mode":
      return value === 1 ? "Major" : value === 0 ? "Minor" : "Unknown Mode";

    case "key":
      return keyMap[value] || "Unknown Key";

    case "danceability":
    case "energy":
    case "acousticness":
    case "instrumentalness":
    case "liveness":
    case "speechiness":
    case "valence":
      return `${(value * 100).toFixed(2)}%`;

    case "loudness":
      return `${value.toFixed(2)} dB`;

    case "tempo":
      return `${value.toFixed(2)} BPM`;

    case "time_signature":
      return `${value}/4`;

    default:
      return value; // Default case for unhandled features
  }
}

// Function to format level text for display
export function formatLevelText(level) {
  return level
    .replace(/_/g, " ") // Replace underscores with spaces
    .replace(/\b\w/g, (char) => char.toUpperCase()); // Capitalize each word
}

// Function to group an array into rows of a specified size
export function groupLevelsByRow(levels, itemsPerRow) {
  const rows = [];
  for (let i = 0; i < levels.length; i += itemsPerRow) {
    rows.push(levels.slice(i, i + itemsPerRow));
  }
  return rows;
}
