// Code to render the application using createRoot
// Import the necessary modules
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter as Router } from "react-router-dom";
import App from "./App";
import "./index.css";

// Find the root DOM element
const rootElement = document.getElementById("root");

// Use createRoot to render the application
const root = createRoot(rootElement);
root.render(
  <Router>
    <App />
  </Router>
);
