// Code to render the search bar component
// Imports for the SearchBar component
import React, { useState } from "react";
import { MagnifyingGlassIcon } from "@heroicons/react/24/outline";
import { Tooltip } from "react-tooltip";

// SearchBar component
const SearchBar = ({ onSearch }) => {
  const [query, setQuery] = useState("");

  // Function to handle the search button click
  const handleSearch = () => {
    if (query.trim()) {
      onSearch(query);
    }
  };

  // Function to handle the enter key press
  const handleKeyPress = (e) => {
    if (e.key === "Enter") {
      handleSearch();
    }
  };

  // Return the search bar component
  return (
    <div className="relative flex items-center">
      <input
        type="text"
        id="search-bar"
        value={query}
        onChange={(e) => setQuery(e.target.value)}
        onKeyPress={handleKeyPress}
        placeholder="Search for songs..."
        className="rounded-full bg-black text-white p-2 pl-4 pr-10 w-[200px] sm:w-[300px]"
        data-tooltip-id="SearchBar"
        data-tooltip-content="Search for a Beat's Deetz"
        aria-label="Search Bar"
        role="search"
      />
      {/* Tooltip for the search bar */}
      <Tooltip id={"SearchBar"} />
      <button
        onClick={handleSearch}
        className="absolute right-3"
        aria-label="Search Button"
        role="button"
      >
        {/* Magnifying glass icon for search button*/}
        <MagnifyingGlassIcon className="h-5 w-5 text-white"
        data-tooltip-id="SearchButton"
        data-tooltip-content="Search"/>
        <Tooltip id={"SearchButton"} />
      </button>
    </div>
  );
};

// Export the SearchBar component
export default SearchBar;
