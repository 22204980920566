// Code to render the Navbar component with the search bar, Home and Spotify Buttons

import React from "react";
import SearchBar from "./SearchBar";
import { Tooltip } from "react-tooltip";

// Navbar component to render the search bar, Home and Spotify buttons
const Navbar = ({ onSearch }) => {
  // Function to handle the logo click and navigate to the home page
  const handleLogoClick = () => {
    window.location.href = "/";
  };

  // Function to handle the Spotify logo click and navigate to Spotify
  const handleSpotifyClick = () => {
    window.open("https://open.spotify.com/", "_blank");
  };

  return (
    <nav className="bg-spotify-green flex justify-between items-center p-4  text-black">
      {/* Beatdeetz Logo */}
      <img
        src={`${process.env.PUBLIC_URL}/img/bd-black.png`}
        alt="BeatDeetz Logo"
        onClick={handleLogoClick}
        className="h-10 w-auto cursor-pointer"
        data-tip="Return Home for BeatDeetz"
        data-tooltip-id="BeatDeatz"
        data-tooltip-content="Home"
        aria-label="Home Button"
        role="button"
      />
      {/* Tooltip for the logo */}
      <Tooltip id={"BeatDeatz"} />

      {/* Search bar */}
      <SearchBar onSearch={onSearch} />

      {/* Spotify Logo */}
      <img
        src={`${process.env.PUBLIC_URL}/img/Spotify_Black.png`}
        alt="Spotify Logo"
        onClick={handleSpotifyClick}
        className="h-10 w-auto cursor-pointer"
        data-tip="Login for Full Playback in Visualizer Mode"
        data-tooltip-id="Spotify"
        data-tooltip-content="Login to Spotify for Full Playback in Visualizer Mode"
        aria-label="Link to Spotify"
        role="button"
      />
      {/* Tooltip for the Spotify logo */}
      <Tooltip id={"Spotify"} />
    </nav>
  );
};

// Export the Navbar component
export default Navbar;
