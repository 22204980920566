// Code to render the track panel component which displays track details
// Imports for the TrackPanel component
import React from "react";
import { formatDuration } from "./utils";
import { Tooltip } from "react-tooltip";
import { generateTooltipDetails, TooltipTag } from "./TooltipDetail";

// TrackPanel component
const TrackPanel = ({ track, features }) => {
  // Generate tooltip details
  const tooltipDetails = generateTooltipDetails(track, features);

  const artistTooltip = tooltipDetails.find((detail) => detail.id === "artist");
  const albumTooltip = tooltipDetails.find((detail) => detail.id === "album");
  const durationTooltip = tooltipDetails.find(
    (detail) => detail.id === "duration"
  );

  // Return the track panel component
  return (
    <div className="lcd-box p-4 m-4 rounded-lg bg-green-300 text-black">
      <h2
        // Display the track name
        className="text-xl"
        data-tooltip-id="track"
        data-tooltip-content="The name of the track."
      >
        {track.name}
      </h2>
      <Tooltip id="track" className="max-w-[400px] break-words" />

      <TooltipTag
        // Display the artist name
        tooltipId={artistTooltip?.id}
        tooltipContent={
          artistTooltip?.content || "Artist information not available"
        }
      >
        <p>Artist: {track.artists.map((artist) => artist.name).join(", ")}</p>
      </TooltipTag>

      <TooltipTag
        // Display the album name
        tooltipId={albumTooltip?.id}
        tooltipContent={
          albumTooltip?.content || "Album information not available"
        }
      >
        <p>Album: {track.album.name}</p>
      </TooltipTag>

      <TooltipTag
        // Display the duration of the track
        tooltipId={durationTooltip?.id}
        tooltipContent={
          durationTooltip?.content || "Duration information not available"
        }
      >
        <p>Duration: {formatDuration(track.duration_ms)}</p>
      </TooltipTag>
    </div>
  );
};

// Export the TrackPanel component
export default TrackPanel;
