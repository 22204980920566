// Code to render the horizontal slider component which shows the value of a feature
// Imports for the component
import React from "react";
import styled from "styled-components";
import ReactSlider from "react-slider";

// Styling for the horizontal slider component
const StyledSlider = styled(ReactSlider)`
  width: 100%; /* Full width for horizontal slider */
  height: 25px;
`;

// Styling for the thumb of the horizontal slider component
const StyledThumb = styled.div`
  height: 20px;
  width: 60px;
  background-image: url("/img/level.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: -25px;
  margin-top: 3px;
  transform: rotate(90deg);
`;

// Thumb component to render the thumb of the horizontal slider
const Thumb = ({ key: keyProp, ...props }, state) => (
  <StyledThumb key={keyProp} {...props} />
);

// Styling for the track of the horizontal slider component
const StyledTrack = styled.div`
  background: ${(props) =>
    props.index === 2 ? "#f00" : props.index === 1 ? "#a3a3a3" : "#1ED760"};
  border-radius: 999px;
  height: 100%;
`;

// Track component to render the track of the horizontal slider
const Track = ({ key: keyProp, ...props }, state) => (
  <StyledTrack key={keyProp} {...props} index={state.index} />
);

// Styling for the container of the horizontal slider component
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: full;
  margin: 20px;
`;

// HorizontalSlider component to render the horizontal slider
const HorizontalSlider = ({ value, label }) => (
  <StyledContainer>
    <div className="flex gap-x-5 items-center min-w-full">
      <div className="lcd-box p-2 rounded-lg bg-green-300 text-black text-center w-[80px]">
        <p>{value.toFixed(2)}</p>
      </div>
      <StyledSlider
        value={value}
        min={0}
        max={100}
        orientation="horizontal"
        renderTrack={Track}
        renderThumb={Thumb}
        disabled={true}
      />
    </div>
    <br></br>
    <p className="text-white font-bold mb-2">{label}</p>
  </StyledContainer>
);

// Export the HorizontalSlider component
export default HorizontalSlider;
