// Code to render the scrolling text component
// Imports for the ScrollingText component
import React, { useEffect, useRef, useState } from "react";

// ScrollingText component
const ScrollingText = ({ text }) => {
  const [duration, setDuration] = useState(0);
  const textRef = useRef(null);

  // Calculate the duration based on the text width and screen width
  useEffect(() => {
    if (textRef.current) {
      const textWidth = textRef.current.scrollWidth;
      const screenWidth = window.innerWidth;

      // Calculate duration based on text width and screen width
      // 30 seconds for a full scroll if the text is large and adjusts based on width
      const calculatedDuration = Math.max((textWidth / screenWidth) * 30, 8); // Minimum duration of 5s for very small text
      setDuration(calculatedDuration);
    }
  }, [text]);

  // Return the scrolling text component
  return (
    <div
      ref={textRef}
      className="scrolling-text"
      style={{
        animation: `scroll ${duration}s linear infinite`,
        whiteSpace: "nowrap", // Ensures text doesn't break into new lines
      }}
    >
      {text}
    </div>
  );
};

// Export the ScrollingText component
export default ScrollingText;
