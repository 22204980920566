// Code to render the cookie consent component, which displays a popup to accept or decline cookies
// Imports for the CookieConsent component
import React, { useState, useEffect } from "react";
import Cookies from "js-cookie";

// CookieConsent component
const CookieConsent = () => {
  const [isVisible, setIsVisible] = useState(false);

  useEffect(() => {
    // Check if the user has already accepted cookies
    const cookieConsent = Cookies.get("cookieConsent");
    if (!cookieConsent) {
      setIsVisible(true); // Show the popup if not accepted
    }
  }, []);

  // Function to handle the user accepting cookies
  const handleAccept = () => {
    // Set a cookie when the user accepts
    Cookies.set("cookieConsent", "true", { expires: 365 });
    setIsVisible(false); // Hide the popup after accepting
  };

  if (!isVisible) {
    return null; // Don't render anything if the popup is hidden
  }

  // Function to handle the user declining cookies
  const handleDecline = () => {
    Cookies.set("cookieConsent", "false", { expires: 365 });
    setIsVisible(false); // Hide the popup if the user declines
  };

  // Render the cookie consent popup
  return (
    <div className="cookie-consent-popup">
      <div className="cookie-consent-content">
        <p>
          We use cookies to enhance your experience. By continuing to visit this
          site, you agree to our use of cookies.
        </p>
        {/* Accept and Decline buttons */}
        <button
          onClick={handleAccept}
          className="accept-btn"
          aria-label="Accept Button"
          role="button"
        >
          Accept
        </button>
        <button
          onClick={handleDecline}
          className="decline-btn"
          aria-label="Decline Button"
          role="button"
        >
          Decline
        </button>
      </div>
    </div>
  );
};

// Export the CookieConsent component
export default CookieConsent;
