// Code to render the Spotify player component
// Imports for the SpotifyPlayer component
import { Tooltip } from "react-tooltip";

// SpotifyPlayer component
const SpotifyPlayer = ({ trackId }) => {
  return (
    <div className="spotify-player mt-2">
      <iframe
        src={`https://open.spotify.com/embed/track/${trackId}`} // Embeds the Spotify track using the track ID
        width="250"
        height="250"
        allow="encrypted-media"
        allowFullScreen
        title="Spotify Player"
        data-tooltip-id="spotify-player"
        data-tooltip-content="Spotify Player"
        aria-label="Spotify Player"
      ></iframe>
      {/* Tooltip for the Spotify player */}
      <Tooltip id="spotify-player" className="max-w-[400px] break-words" />
    </div>
  );
};

// Export the SpotifyPlayer component
export default SpotifyPlayer;
