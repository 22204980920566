// Code to render the tempo gauge component which shows the tempo of a song
// Imports for the component
import React, { useEffect, useRef } from "react";
import * as echarts from "echarts";
import { TooltipTag } from "./TooltipDetail";

// TempoGauge component to render the tempo gauge
const TempoGauge = ({ tempo, tooltipId, tooltipContent }) => {
  const chartRef = useRef(null);

  // Use effect to render the tempo gauge
  useEffect(() => {
    const myChart = echarts.init(chartRef.current);

    const option = {
      series: [
        {
          // Set the options for the tempo gauge
          type: "gauge",
          min: 0,
          max: 200,
          radius: "50%",
          axisLine: {
            lineStyle: {
              color: [[1, "#ffffff"]],
              width: 3,
            },
          },
          splitLine: {
            // Set the style for the split line of the tempo gauge
            distance: -18,
            length: 18,
            lineStyle: {
              color: "#ffffff",
            },
          },
          axisTick: {
            // Set the style for the axis tick of the tempo gauge
            distance: -12,
            length: 10,
            lineStyle: {
              color: "#ffffff",
            },
          },
          axisLabel: {
            // Set the style for the axis label of the tempo gauge
            distance: -40,
            color: "#ffffff",
            fontSize: 15,
          },
          pointer: {
            // Set the style for the pointer of the tempo gauge
            show: true,
            length: "115%",
            itemStyle: {
              color: "#1ED760",
            },
          },
          detail: {
            // Set the style for the detail of the tempo gauge
            formatter: `{value} BPM`,
            valueAnimation: false,
            fontSize: 15,
            color: "#ffffff",
            offsetCenter: {
              0: "0%",
              1: "100%",
            },
          },
          title: {
            // Set the title for the tempo gauge
            formatter: `Tempo (BPM)`,
            fontSize: 15,
            color: "#ffffff",
            offsetCenter: {
              0: "0%",
              1: "130%",
            },
          },
          data: [
            {
              // Set the value for the tempo gauge
              value: tempo,
              name: "Tempo (BPM)",
            },
          ],
        },
      ],
    };

    myChart.setOption(option);

    return () => {
      myChart.dispose();
    };
  }, [tempo]);

  return (
    // TooltipTag to show the tooltip when hovering over the tempo gauge
    <TooltipTag tooltipId={tooltipId} tooltipContent={tooltipContent}>
      <div ref={chartRef} style={{ width: "300px", height: "300px" }} />
    </TooltipTag>
  );
};

// Export the TempoGauge component
export default TempoGauge;
