// Code to render the music panel with sliders and knobs
// Imports for the MusicPanel component
import ValenceSlider from "./ValenceSlider";
import PopSlider from "./PopSlider";
import KnobDisplay from "./KnobDisplay";
import ExplicitSwitch from "./ExplicitSwitch";
import ModeSwitch from "./ModeSwitch";
import { generateTooltipDetails } from "./TooltipDetail";

// ControlItem component
const ControlItem = ({ label, children }) => (
  <div className="flex flex-col items-center text-center w-32 md:mr-2 md:ml-2 mb-4">
    <div
      style={{
        height: "60px",
        display: "flex",
        alignItems: "center",
        flexGrow: 1,
        margin: 20,
      }}
    >
      {children}
    </div>
    <p className="mt-auto">
      <strong>{label}</strong>
    </p>
  </div>
);

// MusicPanel component
const MusicPanel = ({ features, track }) => {
  // Generate tooltip details for the music panel
  const tooltipDetails = generateTooltipDetails(track, features);
  const modeTooltip = tooltipDetails.find((detail) => detail.id === "mode");
  const explicitTooltip = tooltipDetails.find(
    (detail) => detail.id === "explicit"
  );
  const acousticnessTooltip = tooltipDetails.find(
    (detail) => detail.id === "acousticness"
  );
  const livenessTooltip = tooltipDetails.find(
    (detail) => detail.id === "liveness"
  );
  const valenceTooltip = tooltipDetails.find(
    (detail) => detail.id === "valence"
  );
  const popularityTooltip = tooltipDetails.find(
    (detail) => detail.id === "popularity"
  );

  // Render the music panel with sliders and knobs
  return (
    <div className="w-full">
      {/* Desktop/Mid View Layout */}
      <div className="hidden md:flex justify-between items-center w-full">
        {/* Mode Switch */}
        <ControlItem label="Mode">
          <ModeSwitch
            mode={features?.mode}
            tooltipId={modeTooltip?.id}
            tooltipContent={modeTooltip?.content}
          />
        </ControlItem>

        {/* Explicit Switch */}
        <ControlItem label="Explicit">
          <ExplicitSwitch
            isExplicit={track?.explicit}
            tooltipId={explicitTooltip?.id}
            tooltipContent={explicitTooltip?.content}
          />
        </ControlItem>

        {/* Acousticness Knob */}
        <ControlItem label="Acousticness">
          <KnobDisplay
            value={features?.acousticness * 100}
            tooltipId={acousticnessTooltip?.id}
            tooltipContent={acousticnessTooltip?.content}
          />
        </ControlItem>

        {/* Liveness Knob */}
        <ControlItem label="Liveness">
          <KnobDisplay
            value={features?.liveness * 100}
            tooltipId={livenessTooltip?.id}
            tooltipContent={livenessTooltip?.content}
          />
        </ControlItem>
      </div>

      {/* Mobile View Layout */}
      <div className="flex flex-col space-y-4 md:hidden">
        <div className="flex justify-between items-center">
          {/* Mode and Explicit Switches in one row on mobile */}
          <ControlItem label="Mode">
            <ModeSwitch
              mode={features?.mode}
              tooltipId={modeTooltip?.id}
              tooltipContent={modeTooltip?.content}
            />
          </ControlItem>
          <ControlItem label="Explicit">
            <ExplicitSwitch
              isExplicit={track?.explicit}
              tooltipId={explicitTooltip?.id}
              tooltipContent={explicitTooltip?.content}
            />
          </ControlItem>
        </div>

        <div className="flex justify-between items-center">
          {/* Acousticness and Liveness Knobs in one row on mobile */}
          <ControlItem label="Acousticness">
            <KnobDisplay
              value={features?.acousticness * 100}
              tooltipId={acousticnessTooltip?.id}
              tooltipContent={acousticnessTooltip?.content}
            />
          </ControlItem>
          <ControlItem label="Liveness">
            <KnobDisplay
              value={features?.liveness * 100}
              tooltipId={livenessTooltip?.id}
              tooltipContent={livenessTooltip?.content}
            />
          </ControlItem>
        </div>
      </div>

      {/* Sliders - these remain consistent across views */}
      <ValenceSlider
        value={(features?.valence * 100).toFixed(2)}
        label="Valence"
        tooltipId={valenceTooltip?.id}
        tooltipContent={valenceTooltip?.content}
      />
      <PopSlider
        value={track.popularity}
        label="Popularity"
        tooltipId={popularityTooltip?.id}
        tooltipContent={popularityTooltip?.content}
      />
    </div>
  );
};

// Export the MusicPanel component
export default MusicPanel;
