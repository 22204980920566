// Code to create a mode switch component which shows if a song is in minor or major mode
// Imports for the component
import React, { useEffect, useState } from "react";
import SwitchSelector from "react-switch-selector";
import styled from "styled-components";
import { TooltipTag } from "./TooltipDetail";
import { v4 as uuidv4 } from "uuid";

// Styling for the mode switch component
const StyledContainer = styled.div`
  display: flex;
  align-items: center;
  width: 120px;
  height: 50px;
  pointer-events: none;
`;

// ModeSwitch component to render the mode switch
const ModeSwitch = ({ mode, tooltipId, tooltipContent }) => {
  // State to manage the selected index
  const [selectedIndex, setSelectedIndex] = useState(mode === 0 ? 0 : 1);
  const minorId = uuidv4();
  const majorId = uuidv4();

  // Effect to update the selected index when mode changes
  useEffect(() => {
    const newIndex = mode === 0 ? 0 : 1;
    setSelectedIndex(newIndex);
  }, [mode]);

  const options = [
    {
      label: <span>Minor</span>,
      value: 0, // Minor mode
      selectedBackgroundColor: "#007BFF", // Blue for minor
      selectedFontColor: "#FFFFFF",
      id: minorId,
      name: "mode-switch",
    },
    {
      label: (
        <span style={{ color: selectedIndex === 1 ? "#000000" : "#ffffff" }}>
          Major
        </span>
      ),
      value: 1, // Major mode
      selectedBackgroundColor: "#FFD700", // Yellow for major
      selectedFontColor: "#000000",
      id: majorId,
      name: "mode-switch",
    },
  ];

  // Return the mode switch component
  return (
    <TooltipTag tooltipId={tooltipId} tooltipContent={tooltipContent}>
      <StyledContainer>
        <SwitchSelector
          options={options}
          initialSelectedIndex={selectedIndex}
          backgroundColor="#353B48" // Background color when not selected
          fontColor="#F5F6FA" // Text color when not selected
          style={{ width: 120, height: 50 }}
          onChange={() => {}} // Keep non-interactive
        />
      </StyledContainer>
    </TooltipTag>
  );
};

// Export the ModeSwitch component
export default ModeSwitch;
