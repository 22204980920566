// Code to render the tooltip list component which displays a list of tooltip details
// Imports for the TooltipList component
import TooltipDetail, { generateTooltipDetails } from "./TooltipDetail";

// TooltipList component
const TooltipList = ({ track, features }) => {
  const details = generateTooltipDetails(track, features);

  // Return the tooltip list component
  return (
    <div>
      {details.map(({ id, content, label, value }) => (
        <TooltipDetail
          key={id}
          tooltipId={id}
          tooltipContent={content}
          label={label}
          value={value}
        />
      ))}
    </div>
  );
};

// Export the TooltipList component
export default TooltipList;
