// Code to render the feature slider component which shows the value of a feature
// Imports for the component
import React from "react";
import HorizontalSlider from "./HorizontalSlider";
import VerticalSlider from "./VerticalSlider";
import { TooltipTag } from "./TooltipDetail";

// FeatureSlider component to render the feature slider
const FeatureSlider = ({
  value,
  label,
  isHorizontal,
  tooltipId,
  tooltipContent,
}) => {
  const capitalizedLabel = label.charAt(0).toUpperCase() + label.slice(1);
  const SliderComponent = isHorizontal ? HorizontalSlider : VerticalSlider;

  // Return the feature slider component
  return (
    // TooltipTag to show the tooltip when hovering over the feature slider
    <TooltipTag tooltipId={tooltipId} tooltipContent={tooltipContent}>
      <SliderComponent value={value} label={capitalizedLabel} />
    </TooltipTag>
  );
};

const SliderGroup = ({
  features,
  sliderType = "vertical",
  labels,
  tooltipDetails = [],
}) => {
  // Check if the slider type is horizontal
  const isHorizontal = sliderType === "horizontal";

  // Return the slider group component
  return (
    <div
      className={
        isHorizontal ? "space-x-4" : "flex justify-center w-full space-x-2"
      }
    >
      {labels.map((label) => {
        const tooltip =
          tooltipDetails.find((detail) => detail.id === label) || {};
        return (
          <div key={label} className="flex-1">
            <FeatureSlider
              value={features?.[label] * 100}
              label={label}
              isHorizontal={isHorizontal}
              tooltipId={tooltip.id || label}
              tooltipContent={
                tooltip.content || `${label} information not available`
              }
            />
          </div>
        );
      })}
    </div>
  );
};

// Export the FeatureSlider component
export default SliderGroup;
