// Code to render the main page, track details page, and example songs page
// Imports the Navbar, SampleSongs, ExampleSongs, TrackDetails, Footer, and CookieConsent components
import React, { useState } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  useNavigate,
} from "react-router-dom";
import Navbar from "./components/Navbar";
import SampleSongs from "./components/SampleSongs";
import ExampleSongs from "./components/ExampleSongs";
import TrackDetails from "./components/TrackDetails";
import Footer from "./components/Footer";
import CookieConsent from "./components/CookieConsent";
import { Tooltip } from "react-tooltip";
import "./fonts.css";

// Main App component
function App() {
  // State variables
  const [selectedTrack, setSelectedTrack] = useState(null);
  const [tracks, setTracks] = useState([]);
  const [searchAttempted, setSearchAttempted] = useState(false);
  const [selectedFeature, setSelectedFeature] = useState(null);

  // Function to navigate to a different page
  const navigate = useNavigate();

  // Function to handle search queries
  const handleSearch = async (searchQuery) => {
    setSelectedTrack(null);
    setSearchAttempted(true);
    // Redirect to the main page for search results
    navigate("/");

    // Fetch search results from the backend
    try {
      // Ease of use commenting out for development/production
      //const response = await fetch(`http://localhost:5000/api/search?query=${searchQuery}`);
      const response = await fetch(`/api/search?query=${searchQuery}`);
      const data = await response.json();

      // Set the search results in the state
      if (data.tracks.items.length > 0) {
        setTracks(data.tracks.items);
      } else {
        setTracks([]);
        console.log("No results found.");
      }
      // Handle errors when fetching search results
    } catch (error) {
      console.error("Error fetching tracks:", error);
      setTracks([]);
    }
  };

  // Function to handle track selection
  const handleTrackSelect = async (track, feature, selectedFeature) => {
    setSelectedTrack(track); // Set the selected track
    setSelectedFeature(feature); // Set the selected feature if available
    // Navigate to the track details page
    navigate("/track-details");

    // Fetch the selected track details and artist genre
    try {
      const artistId = track.artists[0].id;
      // Ease of use commenting out for development/production
      //const artistResponse = await fetch(`http://localhost:5000/api/artist?artistId=${artistId}`);
      const artistResponse = await fetch(`/api/artist?artistId=${artistId}`);
      const artistData = await artistResponse.json();
      setSelectedTrack({ ...track, genre: artistData.genres.join(", ") });
      // Handle errors when fetching track details or artist genre
    } catch (error) {
      console.error("Error fetching track details or artist genre:", error);
    }
  };

  // Function to handle example songs navigation
  const handleExampleSongs = () => {
    // Navigate to the example songs page
    navigate("/example-songs");
  };

  // Function to handle back navigation
  const handleBack = () => {
    setSearchAttempted(false); // Reset search state
    setTracks([]); // Optionally clear search results
    navigate("/"); // Navigate back to the home page with sample songs
  };

  // Render the main app content
  return (
    <div className="App">
      {/* Render the Navbar component with the search handler */}
      <Navbar onSearch={handleSearch} />
      <div className="main-content p-4">
        <CookieConsent />
        <Routes>
          <Route
            path="/"
            element={
              <div>
                <h1
                  className="font-spotify text-2xl text-center"
                  role="heading"
                >
                  Search for a Beat's Deetz!
                </h1>

                {/* Show Back Button and Results if Search is Attempted */}
                {searchAttempted && (
                  // Render the Back Button
                  <div className="mb-4 text-center">
                    <button
                      onClick={handleBack}
                      className="bg-gray-800 hover:bg-gray-300 p-2 rounded transition duration-200 flex justify-start"
                      aria-label="Back Button"
                      role="button"
                      data-tooltip-id="BackButton"
                      data-tooltip-content="Return to Home Page"
                    >
                      ← Back
                    </button>
                    <Tooltip id={"BackButton"} />
                  </div>
                )}

{searchAttempted && tracks.length === 0 && (
  <div className="text-center mt-4">
    <p className="text-gray-500 text-lg">No results found. Please search for something else.</p>
  </div>
)}

                {/* Sample Songs or Search Results */}
                {/* Render the SampleSongs component or the Search results if available*/}
                {!searchAttempted && (
                  <SampleSongs onSelect={handleTrackSelect} />
                )}
                {searchAttempted && tracks.length > 0 && (
                  <div className="mt-4 flex flex-col items-center gap-4 md:flex-row md:flex-wrap md:justify-center">
                    {tracks.map((track) => (
                      <div
                        key={track.id}
                        className="p-4 border rounded-lg shadow-lg cursor-pointer w-full max-w-[300px] md:w-1/6  md:min-h-[400px]"
                        onClick={() => handleTrackSelect(track)}
                        role="button"
                        data-tooltip-id={`tooltip-${track.id}`}
                        data-tooltip-content={`Click to view track details for ${track.name}`}
                      >
                        <img
                          src={track.album.images[0]?.url}
                          alt={`${track.name} album cover`}
                          className="mt-2 w-full h-auto rounded-lg"
                          role="button"
                        />
                        <h2 className="text-xl font-semibold">{track.name}</h2>
                        <p className="text-gray-400">
                          {track.artists
                            .map((artist) => artist.name)
                            .join(", ")}
                        </p>
                        <Tooltip id={`tooltip-${track.id}`} />
                      </div>
                    ))}
                  </div>
                )}

                {/* Show Example Songs Link if Search is not Attempted */}
                {!searchAttempted && (
                  <div className="text-center mt-4">
                    <p>
                      To see examples of songs that highlight the range of the
                      details presented by BEATDEETZ
                    </p>
                    <p>
                      Click{" "}
                      <strong>
                        <span
                          className="text-blue-500 underline cursor-pointer"
                          onClick={handleExampleSongs}
                          aria-label="ExampleSongs"
                          role="button"
                          data-tooltip-id="ExampleSongs"
                          data-tooltip-content="Get Tracks of specific features"
                        >
                          HERE
                        </span>
                      </strong>{" "}
                    </p>
                    <Tooltip id={"ExampleSongs"} />
                  </div>
                )}
              </div>
            }
          />
          {/* Render the TrackDetails component with the selected track and feature */}
          <Route
            path="/track-details"
            element={
              <TrackDetails
                track={selectedTrack}
                selectedFeature={selectedFeature}
              />
            }
          />
          {/* Render the ExampleSongs component with the track selection handler */}
          <Route
            path="/example-songs"
            element={<ExampleSongs onSelect={handleTrackSelect} />}
          />
        </Routes>
      </div>
      <Footer />
    </div>
  );
}

export default App;
