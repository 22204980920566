// Code to render the example songs component, which displays a track based on selected features
// Imports for the ExampleSongs component
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { TooltipTag, generateTooltipDetails } from "./TooltipDetail";
import { Tooltip } from "react-tooltip";

//TODO: Fix issue where minor mode and C key do not process to track details properly

// Function to format the feature value based on the feature type
const formatFeatureValue = (feature, value) => {
  switch (feature) {
    case "danceability":
    case "energy":
    case "acousticness":
    case "instrumentalness":
    case "liveness":
    case "speechiness":
    case "valence":
      return `${(value * 100).toFixed(2)}%`; // Multiply by 100 and format as percentage
    case "mode":
      return value === 1 ? "Major" : "Minor"; // Check for proper 0 or 1 values
    case "loudness":
      return `${value.toFixed(2)} dB`; // Append dB to represent decibels for loudness
    case "tempo":
      return `${value.toFixed(2)} BPM`; // Append with BPM to represent beats per minute for tempo
    case "time_signature":
      return `${value}/4`; // Append with "/4" to represent time signature
    case "key":
      const pitchClasses = [
        "C",
        "C♯/D♭",
        "D",
        "D♯/E♭",
        "E",
        "F",
        "F♯/G♭",
        "G",
        "G♯/A♭",
        "A",
        "A♯/B♭",
        "B",
      ];
      return pitchClasses[value] || "C"; // Return the pitch class or "Unknown
    default:
      return value;
  }
};

// Function to generate a falsified value for a feature based on the level selected
const generateFalsifiedValue = (feature, level) => {
  const ranges = {
    danceability: { low: [0, 0.33], mid: [0.34, 0.66], high: [0.67, 1] },
    energy: { low: [0, 0.33], mid: [0.34, 0.66], high: [0.67, 1] },
    acousticness: { low: [0, 0.33], mid: [0.34, 0.66], high: [0.67, 1] },
    instrumentalness: { low: [0, 0.33], mid: [0.34, 0.66], high: [0.67, 1] },
    liveness: { low: [0, 0.33], mid: [0.34, 0.66], high: [0.67, 1] },
    speechiness: { low: [0, 0.33], mid: [0.34, 0.66], high: [0.67, 1] },
    valence: {
      very_low: [0, 0.2],
      low: [0.21, 0.4],
      mid: [0.41, 0.6],
      high: [0.61, 0.8],
      very_high: [0.81, 1],
    },
    mode: { minor: 0, major: 1 },
    loudness: { low: [-60, -30], mid: [-29, -10], high: [-9, 0] },
    tempo: { low: [40, 80], mid: [81, 120], high: [121, 200] },
    time_signature: { "3/4": 3, "4/4": 4, "5/4": 5, "6/4": 6, "7/4": 7 },
    key: {
      C: 0,
      "C♯/D♭": 1,
      D: 2,
      "D♯/E♭": 3,
      E: 4,
      F: 5,
      "F♯/G♭": 6,
      G: 7,
      "G♯/A♭": 8,
      A: 9,
      "A♯/B♭": 10,
      B: 11,
    },
  };

  // Retrieve the range for the feature and level
  const range = ranges[feature]?.[level];
  if (!range) return "Unknown"; // Fallback for invalid ranges

  if (Array.isArray(range)) {
    // Generate a random value within the range
    const [min, max] = range;
    return Math.random() * (max - min) + min;
  }

  // Return the mapped value
  return range;
};

// Function to render the ExampleSongs component
const ExampleSongs = ({ onSelect }) => {
  const navigate = useNavigate();
  const [selectedFeature, setSelectedFeature] = useState({});
  const [expandedFeatures, setExpandedFeatures] = useState({});
  const [loadingLevels, setLoadingLevels] = useState({});
  const [exampleSongs, setExampleSongs] = useState({});

  // Function to handle the selection of a feature and its value
  const handleFeatureSelection = (feature, selectedFeatureValue) => {
    // Update the state with feature: value format
    setSelectedFeature((prev) => ({
      ...prev,
      [feature]: selectedFeatureValue,
    }));
  };

  // Function to handle the selection of a track
  const handleTrackSelect = async (track, feature, selectedFeatureValue) => {
    // Pass the feature and its value to the parent or further handlers
    onSelect(track, { [feature]: selectedFeatureValue });
  };

  // List of feature categories and their levels
  const featureCategories = [
    { feature: "danceability", levels: ["low", "mid", "high"] },
    { feature: "energy", levels: ["low", "mid", "high"] },
    { feature: "acousticness", levels: ["low", "mid", "high"] },
    { feature: "instrumentalness", levels: ["low", "mid", "high"] },
    { feature: "liveness", levels: ["low", "mid", "high"] },
    { feature: "speechiness", levels: ["low", "mid", "high"] },
    {
      feature: "valence",
      levels: ["very_low", "low", "mid", "high", "very_high"],
    },
    { feature: "mode", levels: ["minor", "major"] },
    { feature: "loudness", levels: ["low", "mid", "high"] },
    { feature: "tempo", levels: ["low", "mid", "high"] },
    { feature: "time_signature", levels: ["3/4", "4/4", "5/4", "6/4", "7/4"] },
    {
      feature: "key",
      levels: [
        "C",
        "C♯/D♭",
        "D",
        "D♯/E♭",
        "E",
        "F",
        "F♯/G♭",
        "G",
        "G♯/A♭",
        "A",
        "A♯/B♭",
        "B",
      ],
    },
  ];

  // Function to fetch the track data for a feature and level
  const fetchLevelData = async (feature, level) => {
    if (exampleSongs[feature]?.[level]) return; // Avoid re-fetching cached data

    // Set the loading state for the feature and level
    setLoadingLevels((prev) => ({
      ...prev,
      [`${feature}-${level}`]: true,
    }));

    try {
      // Update the endpoint to use the backend route for fetching a single track
      // Ease of use commenting out for development/production
      //const response = await fetch(`http://localhost:5000/api/tracks?feature=${feature}&level=${level}`);
      const response = await fetch(`/api/tracks?feature=${feature}&level=${level}`);
      const track = await response.json();

      // Generate falsified feature value for the selected level
      const falsifiedValue = generateFalsifiedValue(feature, level);
      if (track.features) {
        track.features[feature] = falsifiedValue;
      }

      // Update the example songs state with the fetched track
      setExampleSongs((prev) => ({
        ...prev,
        [feature]: {
          ...prev[feature],
          [level]: [track], // Store as an array for consistency
        },
      }));
      // Handle errors when fetching track data
    } catch (error) {
      console.error(`Error fetching data for ${feature} (${level}):`, error);
      // Update the example songs state with an empty array
    } finally {
      setLoadingLevels((prev) => ({
        ...prev,
        [`${feature}-${level}`]: false,
      }));
    }
  };

  // Function to toggle the expanded state of a feature
  const toggleFeature = (feature) => {
    setExpandedFeatures((prev) => ({
      ...prev,
      [feature]: !prev[feature],
    }));
  };

  // Function to handle the click event for a feature level
  const handleLevelClick = (feature, level) => {
    fetchLevelData(feature, level);
  };

  // Function to format the feature text
  const formatLevelText = (level) => {
    return level
      .replace(/_/g, " ")
      .replace(/\b\w/g, (char) => char.toUpperCase());
  };

  // Function to render the track list for a feature level
  const renderTrackList = (tracks, feature, level) => (
    <div className="mt-4 flex flex-wrap justify-center gap-4 md:flex-row md:gap-4 sm:flex-col">
      {Array.isArray(tracks) && tracks.length > 0 ? (
        tracks.map((track) => {
          const featureValue =
            track.features && track.features[feature]
              ? formatFeatureValue(feature, track.features[feature])
              : "Unavaliable";

          // Render the basic track details
          return (
            <div
              key={track.id}
              className="p-4 border rounded-lg shadow-lg cursor-pointer w-auto max-w-[300px]"
              onClick={() => {
                handleFeatureSelection(feature, track.features[feature]);
                handleTrackSelect(track, feature, track.features[feature]);
              }}
              data-tooltip-id={`tooltip-${track.id}`}
              data-tooltip-content={`Click to view track details for ${track.name}`}
            >
              <img
                src={track.album.images[0]?.url}
                alt={`${track.name} album cover`}
                className="mt-2 w-full h-auto rounded-lg"
              />
              <h2 className="text-lg font-semibold">{track.name}</h2>
              <p className="text-gray-400">
                {track.artists.map((artist) => artist.name).join(", ")}
              </p>
              <p className="text-sm text-gray-500">
                {featureValue
                  ? `${formatLevelText(feature)}: ${featureValue}`
                  : ""}
              </p>
              <Tooltip id={`tooltip-${track.id}`} />
            </div>
          );
        })
      ) : (
        <p className="text-gray-500">No tracks available</p>
      )}
    </div>
  );

  // Function to render a feature row with its levels
  const renderFeatureRow = (feature, levels, track, features) => {
    const groupLevelsByRow = (levels, itemsPerRow) => {
      const rows = [];
      for (let i = 0; i < levels.length; i += itemsPerRow) {
        rows.push(levels.slice(i, i + itemsPerRow));
      }
      return rows;
    };

    // Limits rows to 4 for key feature as it has many options
    const isKeyFeature = feature === "key";
    const groupedLevels = isKeyFeature ? groupLevelsByRow(levels, 4) : [levels];

    // Generate tooltip details for the feature
    const tooltipDetails = generateTooltipDetails(track, features);
    const featureTooltipContent =
      tooltipDetails.find((tooltip) => tooltip.id === feature)?.content ||
      `Explore different tracks with varying ${formatLevelText(feature)}`;

    // Render the feature row with its levels
    return (
      <div key={feature} className="mt-4">
        <TooltipTag
          tooltipId={`${feature}`}
          tooltipContent={featureTooltipContent}
        >
          {/* Render the feature title */}
          <h3
            className="text-lg font-semibold text-center capitalize cursor-pointer"
            onClick={() => toggleFeature(feature)}
          >
            {formatLevelText(feature)}
          </h3>
        </TooltipTag>
        {expandedFeatures[feature] && (
          <div className="flex flex-col items-center mt-2">
            {groupedLevels.map((group, rowIndex) => (
              <div
                key={rowIndex}
                className="flex flex-col xl:flex-row xl:gap-4 gap-4 w-full"
              >
                {/* Render the feature levels */}
                {group.map((level) => (
                  <div
                    key={level}
                    className="flex mt-4 flex-col items-center gap-2 w-full xl:flex w-auto justify-center"
                  >
                    <TooltipTag
                      tooltipId={`${feature}-${level}`}
                      tooltipContent={`Explore a track with ${formatLevelText(
                        level
                      )} ${formatLevelText(feature)}`}
                    >
                      {/* Render the feature level button */}
                      <button
                        className={`py-1 px-4 border text-center rounded capitalize w-[120px] ${
                          exampleSongs[feature]?.[level]
                            ? "bg-spotify-green text-black"
                            : "bg-black text-white"
                        }`}
                        aria-label="Feature Level Button"
                        role="button"
                        onClick={() => handleLevelClick(feature, level)}
                      >
                        {formatLevelText(level)}
                      </button>
                    </TooltipTag>
                    {/* Render the track list for the feature level */}
                    {loadingLevels[`${feature}-${level}`] ? (
                      <p className="text-gray-500 mt-2">Loading...</p>
                    ) : (
                      exampleSongs[feature]?.[level] &&
                      renderTrackList(
                        exampleSongs[feature][level],
                        feature,
                        level
                      )
                    )}
                  </div>
                ))}
              </div>
            ))}
          </div>
        )}
      </div>
    );
  };

  // Render the ExampleSongs component
  return (
    <div className="mt-4">
      <button
        onClick={() => navigate(-1)} // Go back to the previous page
        className="bg-gray-800 hover:bg-gray-300 p-2 rounded transition duration-200"
        aria-label="Back Button"
        role="button"
        data-tooltip-id="BackButton"
        data-tooltip-content="Return to Home Page"
      >
        ← Back
      </button>
      <Tooltip id={"BackButton"} />
      {/* Title and description for the ExampleSongs component */}
      <h1 className="text-xl font-spotifyreg text-center">
        Select a feature to see examples across its range.
      </h1>
      <p className="text-center">
        Click a feature, then a level, to load a song that matches, click on the
        track to see more details.
      </p>
      {featureCategories.map(({ feature, levels }) =>
        renderFeatureRow(feature, levels)
      )}
    </div>
  );
};

// Export the ExampleSongs component
export default ExampleSongs;
