//Code to render the vertical slider component which shows the value of a feature
// Imports for the component
import React from "react";
import styled from "styled-components";
import ReactSlider from "react-slider";

// Styling for the vertical slider component
const StyledSlider = styled(ReactSlider)`
  height: 100%; /* Full height for vertical slider */
  width: 25px;
`;

// Styling for the thumb of the vertical slider component
const StyledThumb = styled.div`
  height: 20px;
  width: 60px;
  background-image: url("/img/level.png");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  margin-left: -17px;
`;

// Thumb component to render the thumb of the vertical slider
const Thumb = ({ key: keyProp, ...props }, state) => (
  <StyledThumb key={keyProp} {...props} />
);

// Styling for the track of the vertical slider component
const StyledTrack = styled.div`
  background: ${(props) =>
    props.index === 2 ? "#f00" : props.index === 1 ? "#a3a3a3" : "#1ED760"};
  border-radius: 999px;
  width: 100%;
`;

// Track component to render the track of the vertical slider
const Track = ({ key: keyProp, ...props }, state) => (
  <StyledTrack key={keyProp} {...props} index={state.index} />
);

// Styling for the container of the vertical slider component
const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 300px;
  margin: 0 10px;
`;

// VerticalSlider component to render the vertical slider
const VerticalSlider = ({ value, label }) => (
  <StyledContainer>
    <div className="lcd-box p-2 rounded-lg bg-green-300 text-black text-center w-[80px]">
      <p>{value.toFixed(2)}</p>
    </div>
    <br></br>
    <StyledSlider
      value={value}
      min={0}
      max={100}
      orientation="vertical"
      renderTrack={Track}
      renderThumb={Thumb}
      invert={true}
      disabled={true}
    />
    <p className="text-white font-bold mb-2">{label}</p>
  </StyledContainer>
);

// Export the VerticalSlider component
export default VerticalSlider;
